import React from 'react'
import { graphql } from 'gatsby'
// import Img from 'gatsby-image'
import SEO from '../components/seo/SEO'
import Texte from '../components/Texte'
import Cta from '../components/Cta'

import { _getZonesPath } from '../lib/helpers'
// import { WrapperContext } from '../components/Layout';

export const pageQuery = graphql`
  query HomeQuery($locale: String!) {
    sanityPage(template: {eq: "home"}, locale: {eq: $locale}) {
      ...page
      liste {
        title
        texte
      }
    }
  }
`

const Home = ({data : { sanityPage }}) => {
  // // const {data: { sanityPage }, errors} = props
  // const _WrapperContext = useContext(WrapperContext)
  // // const { settings: { video } } = _WrapperContext
  // const { video } = _WrapperContext
  // const { video } = settings
  const {
    title,
    texte,
    seo,
    liste
  } = sanityPage
// console.log(video)
  // const [playing, setPlaying] = useState(true)


  return (
    <div className="home ">
      <SEO
        pageTitle={title}
        pageDescription={seo.description}
        template="template-home"
        pageBanner={seo.image.asset.url}
        // pathname={location.pathname}
      />

      <div className="xs-only home-bg"></div>
      <div className="container ">
        <aside className="aside ">
          <h1>{title}</h1>
          <Texte texte={texte} />
          
          <ul className="liste">
            {liste.map( (item, i) => (
              <li key={i}>
                <div className="title fPrim strong">{item.title}</div>
                <div className="texte">{item.texte}</div>
              </li>
            ))}
          </ul>
          
          <Cta label="visiter" url={_getZonesPath()} icon="arrow-e" isLg={true} />
        
        </aside>
      </div>
      
    </div>
  )
}

export default Home
